import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { Loader } from 'semantic-ui-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useFetch from 'use-http';
import Footer from 'portal-components/Footer';
import Navigation from 'portal-components/Navigation';

import getRoutes from './routes';
import menuItems from './menu_data';
import { leftLinks, rightLinks } from './footer_data';

import ScrollToTop from '../components/ScrollToTop';

import { loadLang } from '../actions/lang.actions';
import { userLang } from '../actions/userLang.actions';
import { requestLogout } from '../actions/login.actions';
import { toggleCustomerType } from '../actions/customerType.actions';

import darkLogo from '../assets/logo-dark.svg';
import lightLogo from '../assets/logo-light.svg';

import './style.css';
import { CookieBanner } from '../components/CookieBanner';

// Figure out if it better moving brand name and cms url in config.json
const BRAND_NAME = 'LilaConnect';
const CMS_URL = 'https://cms-at1-lilaconnect.lilaconnect.com/wp-json/wp/v2';

const App = (props) => {
  const {
    init,
    config,
    release,
    userLang,
    loadLang,
    languages,
    objectGroups,
    customerType,
    requestLogout,
    toggleCustomerType,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // INITIALIZE fetch to perform requests
  const { get, response } = useFetch('./');

  // DEFINE the local states
  const [locale] = useState(config.languages[0].id);
  const [finalMessages, setFinalMessages] = useState(languages[locale]);

  // GET the messages of a certain language
  const getMessages = async () => {
    await get(`compiled-lang/${locale}.json`);

    if (response.ok) {
      loadLang({ [locale]: response.data });
    }
  };

  // ON component MOUNT
  useEffect(() => {
    // INJECT on mount the tracking script if users already gave the cookie consent
    // if (localStorage.getItem('consent')) {
    //   injectGAScript(config.google_tm_container_id);
    // }

    // COMPARE browser and config languages
    config.languages.forEach((el) => {
      if (navigator.language.substring(0, 2) === el.id) {
        userLang(el.id);
      } else {
        userLang(config.languages[0].id);
      }
    });

    // LOAD the messages
    getMessages();
  }, []);

  // SET the final messages
  useEffect(() => {
    if (Object.keys(languages).length > 0 && locale) {
      // setFinalMessages(override(finalMessages, languages[locale], true));
      setFinalMessages({ ...finalMessages, ...languages[locale] });
    }
  }, [languages, locale]);

  // LOGOUT users
  const handleLogoutClick = () => {
    requestLogout();

    sessionStorage.removeItem('api_key');
    navigate(config.base_url);
  };

  // SET the customer type according to user selection
  const handleCustomerTypeChange = (event, data) => {
    // SET customer type on Redux store
    toggleCustomerType(data.name);
    // SET toggled customer type on local storage
    localStorage.setItem('customerType', data.name);
  };

  // DEFINE the menu items
  let items = menuItems;
  // Change the login menu item to logout for logged-in users
  if (init.username) {
    items = items.map((item) =>
      item.id !== 'login'
        ? item
        : {
            ...item,
            message: 'logout',
            action: handleLogoutClick,
          },
    );
  }
  // Filter the items that have to be shown only when innet
  items = items.filter((item) => !(item.innet && init.autologin_object === null));

  // SHOW a loading spinner until data is available
  if (!init || !init.role || !finalMessages) {
    return <Loader active style={{ color: 'var(--primaryColor)' }} />;
  }

  // DEFINE the properties to pass to the routes
  let routeProps = {
    init,
    config,
    locale,
    role: init.role,
    apiKey: init.api_key,
    cities: objectGroups,
    brandName: BRAND_NAME,
    cmsURL: CMS_URL,
    apiUrl: config.api,
    baseURL: config.base_url,

    networkOperator: config.network_operator,
    location: {
      ...location,
      query: Object.fromEntries([...searchParams]),
    },
  };

  // DEFINE whether the customer type switch has to be shown in the top navigation bar
  const customerTypeSwitchStatus = ['services']
    .map((item) => `${config.base_url}${item}`)
    .reduce((acc, cur) => {
      // When accessed by the Personal Area, the Services page has no customer type switch
      // since users can order only services of their customer type
      if (
        cur === `${config.base_url}services` &&
        location.search.indexOf('object=') >= 0 &&
        location.search.indexOf('object_group=') >= 0
      ) {
        return false;
      }

      return acc || cur === location.pathname;
    }, false);

  return (
    <IntlProvider locale={locale} messages={finalMessages}>
      <div id="app">
        <ScrollToTop />

        <Navigation
          items={items}
          currentRole={init.role}
          baseURL={config.base_url}
          sticky
          logoURL={darkLogo}
          mobileLogoURL={lightLogo}
          residentialBusinessValue={customerTypeSwitchStatus && customerType}
          handleResidentialBusiness={customerTypeSwitchStatus && handleCustomerTypeChange}
          currentPathname={window.location.pathname.split('/').slice(-1)[0]}
        />

        <div className="page">{getRoutes(routeProps)}</div>

        {config && release && (
          <Footer
            logo={darkLogo}
            release={release}
            leftLinks={leftLinks(config.base_url)}
            rightLinks={rightLinks(config.base_url)}
            copyrightText={`© ${new Date().getFullYear()} VNEXT AB, Alle Rechte vorbehalten.`}
          />
        )}

        <CookieBanner
          locale={locale}
          baseURL={config.base_url}
          googleTagManagerContainerId={config.google_tm_container_id}
        />
      </div>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  init: state.init,
  languages: state.lang,
  userLang: state.userLang,
  customerType: state.customerType,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadLang,
      userLang,
      requestLogout,
      toggleCustomerType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
