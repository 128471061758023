export const leftLinks = baseURL => [
  {
    label: 'GLASFASERDIREKT',
    URL: `${baseURL}full-fibre`,
  },
  {
    label: 'PRIVATKUNDEN',
    URL: `${baseURL}residential`,
  },
  {
    label: 'GESCHÄFTSKUNDEN',
    URL: `${baseURL}business`,
  },
  {
    label: 'ÜBER UNS',
    URL: `${baseURL}about-us`,
  },
];
export const rightLinks = baseURL => [
  {
    label: 'FAQs',
    URL: `${baseURL}faq`,
  },
  {
    label: 'Kontakt',
    URL: `${baseURL}contact-us`,
  },
  {
    label: 'AGB',
    URL: `${baseURL}terms-and-conditions`,
  },
  {
    label: 'Datenschutz',
    URL: `${baseURL}data-protection`,
  },
  {
    label: 'Widerrufsrecht',
    URL: `${baseURL}cancellation-policy`,
  },
  {
    label: 'Cookie policy',
    URL: `${baseURL}cookie-policy`,
  },
  {
    label: 'Impressum',
    URL: `${baseURL}impressum`,
  },
];
