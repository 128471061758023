const menuItems = [
  {
    id: 'full_fibre',
    url: 'full-fibre',
    message: 'GLASFASERDIREKT',
    secondary: true,
    roles: ['anonymous'],
    active: true,
  },
  {
    id: 'residential',
    url: 'residential',
    message: 'PRIVATKUNDEN',
    secondary: true,
    roles: ['anonymous'],
    active: true,
  },
  {
    id: 'business',
    url: 'business',
    message: 'GESCHÄFTSKUNDEN',
    secondary: true,
    roles: ['anonymous'],
    active: true,
  },
  {
    id: 'offers',
    url: 'offers',
    message: 'PRODUKTE',
    secondary: true,
    roles: ['anonymous'],
    active: true,
  },
  {
    id: 'get_connected',
    url: 'coverage',
    message: 'VERFÜGBARKEIT PRÜFEN',
    secondary: true,
    roles: ['anonymous', 'autologin', 'customer'],
    active: true,
  },
  {
    id: 'news',
    url: 'news',
    message: 'AKTUELLES',
    secondary: true,
    roles: ['anonymous', 'autologin', 'customer'],
    active: true,
  },
  {
    id: 'faq',
    url: 'faq',
    message: 'FAQS',
    secondary: true,
    roles: ['anonymous', 'autologin', 'customer'],
    active: true,
  },
  {
    id: 'about_us',
    url: 'about-us',
    message: 'ÜBER UNS',
    secondary: true,
    roles: ['anonymous'],
    active: true,
  },
  {
    id: 'my_places',
    url: 'my-places',
    message: 'PERSÖNLICHER BEREICH',
    secondary: true,
    roles: ['autologin', 'customer'],
    active: true,
  },
  {
    id: 'installation',
    url: 'installer',
    message: 'INSTALLER',
    secondary: true,
    roles: ['installer'],
    active: true,
  },
  {
    id: 'login',
    url: 'login',
    icon: 'user circle',
    secondary: true,
    roles: ['anonymous', 'autologin', 'customer', 'installer'],
    active: false,
  },
];

export default menuItems;
